<template>
  <div class="home">
    <div class="home-contents">
      <div>
        <img alt="Mio3io" src="../assets/img/mio3io_logo.svg" width="200" />
      </div>

      <hr>

      <ul class="links">
        <li>
          <a href="https://mio3works.booth.pm/"><span class="icon"><img alt="o" src="/img/logo_booth.svg" width="24"
                height="24" /></span>BOOTH</a>
        </li>
        <li>
          <a href="https://twitter.com/mio3io"><span class="icon"><img alt="o" src="/img/logo_twitter.svg" width="24"
                height="24" /></span>Twitter</a>
        </li>
        <!-- <li>
          <a href="https://github.com/mio3io"><span class="icon"><img alt="o"
                src="/img/logo_github.svg" width="24" height="24" /></span>GitHub</a>
        </li> -->
      </ul>
      <div class="document-link">
        <a href="https://avatar.mio3io.com">Avatar Documents</a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
};
</script>

<style lang="scss">
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.home-contents {
  display: inline-block;
  margin: auto auto;
}

.links {
  display: flex;
  justify-content: center;
  margin: 20px auto 0 auto;
  padding: 0;
  font-size: 14px;

  li {
    display: block;
    margin: 4px;
    padding: 4px;

    span.icon {
      vertical-align: middle;
      margin-right: 4px;
    }
  }

  a {
    text-decoration: none;
  }
}

.document-link {
  a {
    display: block;
    margin: 20px 8px 0 8px;
    padding: 8px;
    background: #e3e5e8;
    color: #7f868f;
    text-align: center;
    text-decoration: none;
    transition: all .25s;

    &:hover {
      background: #bddde1;
      color: #357094;
    }
  }
}

hr {
  height: 8px;
  border: none;
  background-size: auto auto;
  background-color: rgba(255, 255, 255, 1);
  background-image: repeating-linear-gradient(130deg, transparent, transparent 4px, rgba(221, 221, 221, 1) 4px, rgba(221, 221, 221, 1) 5px);
}
</style>
